import startCase from 'lodash.startcase';
import { endOfMonth, endOfQuarter, endOfWeek, startOfMonth, startOfQuarter, startOfWeek } from 'date-fns';
import orderBy from 'lodash.orderby';
import uniqBy from 'lodash.uniqby';

import { fuzzyDateValue, FuzzyScale } from '@tc/FuzzyDate';
import { tzDate } from '@tc/util/date';
import { getConfig } from 'src/config';
import {
  DepartmentProject as FetchedDepartmentProject,
  DepartmentProjectDataEdges,
  DepartmentProjectEdges,
  DepartmentProjectUpdatesEdges,
} from '@queries/GetDepartmentProjects';
import { GetDepartmentProjectsQuery } from '@generated/GetDepartmentProjects';
import { findUniqueInitiativeTag } from '@shared/utils/initiative';
import { createInitiativeLink } from '@shared/utils/filter';
import { threeMonthsAgo } from '@shared/utils/sort';
import { Entity, Status, Tags } from 'src/graphql/types';
import { getEntityTypeAndTags } from '@shared/utils/entity';
import { WithUrl } from '@shared/utils/navbarSearch';
import { Department } from 'src/store/departmentStore';
import { getDepartmentTagName } from '@program/children/utils/initiativeProjectList';
import { ProjectTagEdge, Tag } from '@queries/GetProjectPageItems';
import { UserWorkspaceDetails } from '@store/workspaceStore';

import {
  buildHierarchyNL,
  flattenChildren,
  getMilestoneHierarchy,
  HierarchyEntities,
  ProjectType,
} from './departmentTable';
import {
  DepartmentProject as DepartmentProjectWithPillar,
  DepartmentProject as DepartmentProjectType,
  FilterKeys,
  getPillarName,
} from './filters';

export type DepartmentProject = WithUrl<
  FetchedDepartmentProject & { supposedType?: ProjectType; type: ProjectType; initiativeTag?: Tag }
>;

type DepartmentEntities = {
  departmentPrograms: DepartmentProject[];
  departmentInitiatives: DepartmentProject[];
  departmentProjects: DepartmentProject[];
  departmentInvalidEntities: DepartmentProject[];
};

export const DEPARTMENT_NAME_TAG_REGEXP = /^dept-(-[a-z0-9]+)+$/;

export const DEPARTMENT_NAME_WITH_PILLAR_TAG_REGEXP = /^dept-(-[a-z0-9]+)+-(-[a-z0-9]+)+$/;

export const getDepartmentPillarRegExp = (departmentTag: string) => new RegExp(`^${departmentTag}-(-[a-z0-9]+)+$`);

export const filterProjectWithoutValidDeptOrPillarTag = (departmentTag: string, project: DepartmentProject) => {
  const pillarRegExp = getDepartmentPillarRegExp(departmentTag);

  return project.tags.edges.some(
    ({ node: { name } }) => DEPARTMENT_NAME_TAG_REGEXP.test(name) || pillarRegExp.test(name)
  );
};

export const getProjectWithPillars = (departmentTag: string, project: DepartmentProject): DepartmentProjectType => {
  const pillarTagRegExp = getDepartmentPillarRegExp(departmentTag);

  const pillars = project.tags.edges.reduce<string[]>((acc, { node: { name } }) => {
    if (pillarTagRegExp.test(name)) {
      acc.push(getPillarName(name));
    }

    return acc;
  }, []);

  return { ...project, pillars };
};

export const getDepartment = (departmentTagName: string, departments?: Department[] | null) =>
  departments?.find(({ label }) => departmentTagName === label);

export const getLabelLink = (id: string, workspace: UserWorkspaceDetails) =>
  `${getConfig().homeCentralUrl}/o/${workspace?.organisationId}/s/${workspace?.cloudId}/tag/${id}/work`;

export const generateDepartmentPageTql = (departmentTagName: string, isDonePhase: boolean) =>
  `(archived = false) AND (label = ${departmentTagName} OR label LIKE ${departmentTagName}--) AND (targetDate >= ${threeMonthsAgo}) AND ${
    isDonePhase ? `(${FilterKeys.PROJECT_STATUS} = ${Status.DONE})` : `(${FilterKeys.PROJECT_STATUS} != ${Status.DONE})`
  }`;

export const departmentPageTql = (departmentTagName: string) =>
  `(archived = false) AND (label = ${departmentTagName} OR label LIKE ${departmentTagName}--) AND (targetDate >= ${threeMonthsAgo})`;

export const departmentProjectTql = (projectKey: string) => `(archived = false) AND (key = ${projectKey})`;

export const getDepartmentEntities = (
  workspace: UserWorkspaceDetails,
  departmentData?: GetDepartmentProjectsQuery['projectTql']['edges']
) => {
  const defaultValue = {
    departmentPrograms: [],
    departmentInitiatives: [],
    departmentProjects: [],
    departmentInvalidEntities: [],
  };

  if (!departmentData) {
    return defaultValue;
  }

  return departmentData.reduce<DepartmentEntities>((acc, { node }) => {
    const { type, programTags, initiativeTags, sizeTags } = getEntityTypeAndTags(node);
    const [programTag] = programTags;

    if (type === Entity.PROGRAM && programTag) {
      acc.departmentPrograms.push({
        ...node,
        url: `/program/${node.key}`,
        target: '_self',
        type: ProjectType.PROGRAM,
      });

      return acc;
    }

    const initiativeTag = findUniqueInitiativeTag(initiativeTags);

    if (type === Entity.INITIATIVE && initiativeTag) {
      acc.departmentInitiatives.push({
        ...node,
        url: createInitiativeLink(initiativeTag),
        initiativeTag,
        target: '_self',
        type: ProjectType.INITIATIVE,
      });

      return acc;
    }

    const { homeCentralUrl } = getConfig();

    if (type === Entity.PROJECT) {
      acc.departmentProjects.push({
        ...node,
        url: `${homeCentralUrl}/o/${workspace?.organisationId}/s/${workspace?.cloudId}/project/${node.key}/updates`,
        target: '_blank',
        type: ProjectType.PROJECT,
      });

      return acc;
    }

    const [sizeTag] = sizeTags;

    if (!sizeTag || sizeTags.length > 1) {
      acc.departmentInvalidEntities.push({
        ...node,
        url: `${homeCentralUrl}/o/${workspace?.organisationId}/s/${workspace?.cloudId}/project/${node.key}/updates`,
        target: '_blank',
        type: ProjectType.INVALID,
      });

      return acc;
    }

    const { name: sizeTagName } = sizeTag;

    if (sizeTagName === Tags.epic || sizeTagName === Tags.project) {
      acc.departmentInvalidEntities.push({
        ...node,
        url: `${homeCentralUrl}/o/${workspace?.organisationId}/s/${workspace?.cloudId}/project/${node.key}/updates`,
        target: '_blank',
        type: ProjectType.PROJECT,
      });

      return acc;
    }

    if (sizeTagName === Tags.program || sizeTagName === Tags.initiative) {
      acc.departmentInvalidEntities.push({
        ...node,
        url: `${homeCentralUrl}/o/${workspace?.organisationId}/s/${workspace?.cloudId}/project/${node.key}/updates`,
        target: '_blank',
        supposedType: sizeTagName === Tags.program ? ProjectType.PROGRAM : ProjectType.INITIATIVE,
        type: ProjectType.INVALID,
      });

      return acc;
    }

    acc.departmentInvalidEntities.push({
      ...node,
      url: `${homeCentralUrl}/o/${workspace?.organisationId}/s/${workspace?.cloudId}/project/${node.key}/updates`,
      target: '_blank',
      type: ProjectType.INVALID,
    });

    return acc;
  }, defaultValue);
};

export const getDeptName = (tagName: string) => startCase(tagName.split('--').pop());

export const getDepartmentsByTags = (tags: ProjectTagEdge[]) =>
  tags.reduce((acc, nextValue) => {
    const depTag = getDepartmentTagName(nextValue.node.name);

    if (depTag && !acc.find((item) => item === getDeptName(depTag))) {
      acc.push(getDeptName(depTag));
    }

    return [...acc];
  }, [] as string[]);

export const getValidDepartmentsTags = (tags: ProjectTagEdge[], allowedDepartmentsLabels: string[]) =>
  tags.reduce((acc, tag) => {
    const {
      node: { name },
    } = tag;
    const depTag = getDepartmentTagName(name);

    if (allowedDepartmentsLabels.includes(depTag!)) {
      acc.push(tag);
    }

    return acc;
  }, [] as ProjectTagEdge[]);

export const getQueryParams = (time: string, confidence: number) => {
  let endDate = null;
  let start = tzDate(time);

  if (fuzzyDateValue[FuzzyScale.MONTH] === confidence) {
    start = startOfMonth(start);
    endDate = endOfMonth(tzDate(time));
  }

  if (fuzzyDateValue[FuzzyScale.WEEK] === confidence) {
    start = startOfWeek(start, {
      weekStartsOn: 1,
    });
    endDate = endOfWeek(tzDate(time), {
      weekStartsOn: 1,
    });
  }

  if (fuzzyDateValue[FuzzyScale.QUARTER] === confidence) {
    start = startOfQuarter(tzDate(time));
    endDate = endOfQuarter(tzDate(time));
  }

  return {
    start,
    end:
      endDate ||
      endOfWeek(tzDate(time), {
        weekStartsOn: 1,
      }),
  };
};
export const getFirstAndLastUpdates = (updates?: DepartmentProjectUpdatesEdges) => {
  if (!updates || updates.length === 0) {
    return [];
  }

  const firstUpdate = updates[updates.length - 1];
  const lastUpdate = updates[0];

  return [firstUpdate, lastUpdate];
};

export const mergeDepartmentsUpdates = (
  initProjects: DepartmentProjectEdges,
  projectsWithUpdates: DepartmentProjectDataEdges
): DepartmentProjectEdges =>
  initProjects.map((project) => ({
    node: {
      ...project.node,
      updates: {
        edges: orderBy(
          uniqBy(
            [
              ...project.node.updates.edges,
              ...getFirstAndLastUpdates(
                projectsWithUpdates.find(({ node }) => node.id === project.node.id)?.node.updates.edges || []
              ),
            ],
            'node.id'
          ),
          'node.creationDate'
        ).reverse(),
      },
    },
  })) as DepartmentProjectEdges;

export const getInitiativesListWithMilestones = ({
  isAssetsEnable,
  programs,
  milestones,
  initiatives,
  projects,
  invalidEntities,
  workspace,
}: HierarchyEntities & {
  workspace: UserWorkspaceDetails;
  milestones?: DepartmentProjectWithPillar[];
  isAssetsEnable?: boolean;
}) => {
  const { allInitiativesList } = buildHierarchyNL(
    {
      programs,
      initiatives,
      projects,
      invalidEntities,
    },
    workspace
  );

  const isUseTagFlow = !isAssetsEnable || !milestones || milestones?.length === 0;

  if (isUseTagFlow) {
    return allInitiativesList.map(flattenChildren).map((item) => ({ ...item, projects: item.children }));
  }

  const keys = milestones.map(({ key }) => key);
  const allEntities = [...programs, ...milestones, ...initiatives, ...projects, ...invalidEntities];

  const milestonesHierarchy = Object.values(getMilestoneHierarchy(allEntities, keys, workspace));

  return [...allInitiativesList, ...milestonesHierarchy]
    .map(flattenChildren)
    .map((item) => ({ ...item, projects: item.children }));
};

export const getAllDepartmentKeys = (departmentData: DepartmentProjectEdges) =>
  departmentData.map(({ node }) => node.key);

export const getDepartmentData = ({
  entities,
  departmentMilestonesHierarchy,
}: {
  entities: DepartmentProjectEdges;
  departmentMilestonesHierarchy: { [key: string]: string[] };
}) => {
  const programsKeys = Object.keys(departmentMilestonesHierarchy);
  const milestonesKeys = Object.values(departmentMilestonesHierarchy).flat();

  return entities.filter(({ node }) => [...programsKeys, ...milestonesKeys].includes(node.key));
};

export const splitEntitiesByType = (entities: DepartmentProject[]) =>
  entities.reduce(
    (
      acc: {
        programs: DepartmentProject[];
        initiatives: DepartmentProject[];
        projects: DepartmentProject[];
        invalidEntities: DepartmentProject[];
        milestones: DepartmentProject[];
      },
      project: DepartmentProject
    ) => {
      if (project.type === ProjectType.PROGRAM) {
        acc.programs.push(project);

        return acc;
      }
      if (project.type === ProjectType.INITIATIVE) {
        acc.initiatives.push(project);

        return acc;
      }
      if (project.type === ProjectType.PROJECT) {
        acc.projects.push(project);

        return acc;
      }

      if (project.type === ProjectType.MILESTONE) {
        acc.milestones.push(project);

        return acc;
      }
      if (project.type === ProjectType.INVALID) {
        acc.invalidEntities.push(project);

        return acc;
      }

      return acc;
    },
    {
      programs: [],
      initiatives: [],
      projects: [],
      invalidEntities: [],
      milestones: [],
    }
  );
