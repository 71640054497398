import { ReactNode } from 'react';

import { getConfig } from 'src/config';
import { Entity, Tags } from 'src/graphql/types';
import { Tag } from '@queries/GetProjectPageItems';
import { Project } from '@queries/SearchProjects';
import { EntityTags, getEntityTypeAndTags, INDEPENDENT_INITIATIVE_NAME_TAG_REGEXP } from '@shared/utils/entity';
import { getInitiativeNameTagRegExp } from '@shared/utils/initiative';
import { apolloClient } from 'src/clients/apolloClient';
import { FIND_PROJECTS_BY_TAG } from '@queries/FindProjectsByTag';
import { FindProjectsByTagQuery, FindProjectsByTagQueryVariables } from '@generated/FindProjectsByTag';
import { generateInitiativePageTql } from '@initiative/children/utils/initiative';
import { UserWorkspaceDetails } from '@store/workspaceStore';

import { getInitiativeLink, getProgramLinkByKey } from './link';

export type ValidationResult = {
  projectError?: string | ReactNode;
  link: string;
  unsuitableTags: Tag[];
  shouldShowSizeTag: boolean;
  initiativeTags: Tag[];
};

type TagNames = { programNameTagName?: string; initiativeNameTagName?: string };

const isInvalidProject = (
  { programTags, initiativeTags }: EntityTags,
  { programNameTagName, initiativeNameTagName }: TagNames
) => {
  const hasInitiativeNameTagName =
    !!initiativeNameTagName && initiativeTags.some(({ name }) => name === initiativeNameTagName);
  const hasInitiativeNameTagForProgram =
    !!programNameTagName &&
    !!initiativeTags.find(({ name }) => getInitiativeNameTagRegExp(programNameTagName).test(name));
  const hasProgramNameTagName = !!programNameTagName && programTags.some(({ name }) => name === programNameTagName);

  return hasInitiativeNameTagName || hasInitiativeNameTagForProgram || hasProgramNameTagName;
};

const getUnsuitableTags = (
  type: Entity,
  { programTags, initiativeTags, sizeTags }: EntityTags,
  { programNameTagName, initiativeNameTagName }: TagNames
) => {
  if (type === Entity.PROGRAM) {
    return [...programTags, ...initiativeTags, ...sizeTags.filter(({ name }) => name !== Tags.program)];
  } else if (type === Entity.INITIATIVE) {
    return [
      ...initiativeTags.filter(
        ({ name }) =>
          (INDEPENDENT_INITIATIVE_NAME_TAG_REGEXP.test(name) && !sizeTags.length) ||
          getInitiativeNameTagRegExp(programNameTagName!).test(name)
      ),
      ...sizeTags.filter(({ name }) => name !== Tags.initiative),
    ];
  }

  const unsuitableSizeTagsForProject = sizeTags.filter(({ name }) => ![Tags.epic, Tags.project].includes(name as Tags));
  const sizeEpicTag =
    sizeTags.length - unsuitableSizeTagsForProject.length === 2 && sizeTags.find(({ name }) => name === Tags.epic);

  return [
    ...initiativeTags.filter(({ name }) => name === initiativeNameTagName),
    ...unsuitableSizeTagsForProject,
    ...(sizeEpicTag ? [sizeEpicTag] : []),
  ];
};

const hasToShowSizeTag = (type: Entity, sizeTags: Tag[]) =>
  !sizeTags.find(
    ({ name }) =>
      (type === Entity.PROGRAM && name === Tags.program) ||
      (type === Entity.INITIATIVE && name === Tags.initiative) ||
      (type === Entity.PROJECT && (name === Tags.epic || name === Tags.project))
  );

export const validateUniqueness = async ({
  query,
  workspaceId,
}: {
  query: string;
  workspaceId: string;
}): Promise<boolean | undefined> => {
  try {
    const response = await apolloClient.query<FindProjectsByTagQuery, FindProjectsByTagQueryVariables>({
      query: FIND_PROJECTS_BY_TAG,
      variables: {
        q: query,
        workspaceId,
      },
      fetchPolicy: 'no-cache',
    });

    return response.data.projectTql.edges.some(({ node: { tags } }) => {
      const { type } = getEntityTypeAndTags({ tags });

      return !!type;
    });
  } catch (err) {
    return undefined;
  }
};

export const validateProjectUniqueness = async ({
  query,
  workspaceId,
  initTag,
}: {
  query: string;
  workspaceId: string;
  initTag: string;
}) => {
  try {
    const response = await apolloClient.query<FindProjectsByTagQuery, FindProjectsByTagQueryVariables>({
      query: FIND_PROJECTS_BY_TAG,
      variables: {
        q: query,
        workspaceId,
      },
      fetchPolicy: 'no-cache',
    });

    return response.data.projectTql.edges[0].node.tags.edges.every((tag) => tag.node.name !== initTag);
  } catch (err) {
    return true;
  }
};

export const isProjectUnique = async (programNameTagName: string, initiativeTags: Tag[], workspaceId: string) => {
  const pendingInitTagUniqueness = Promise.all(
    initiativeTags.map(async (tag) => {
      const { initiativeWithProgramQ } = generateInitiativePageTql(tag.name, programNameTagName);

      return await validateProjectUniqueness({ query: initiativeWithProgramQ, workspaceId, initTag: tag.name });
    })
  );

  const initTagUniqueness = await pendingInitTagUniqueness.then((res) => ({ res: res, loading: false }));

  return {
    isUniqueProject: initTagUniqueness.res.every((tagUniquness) => tagUniquness),
    loading: initTagUniqueness.loading,
  };
};

const doesInitiativeHaveTagForSameProgram = ({ programTags }: EntityTags, programNameTagName?: string) =>
  !programNameTagName || programTags.some(({ name }) => name === programNameTagName);

export const validateEntity = (
  type: Entity,
  project: Project,
  workspace: UserWorkspaceDetails,
  entityTagNames: TagNames,
  onValidationMessage: (type: Entity, project?: Project) => string | ReactNode
): ValidationResult => {
  const { type: entityType, ...entityTagsMap } = getEntityTypeAndTags(project);
  const { initiativeTags, sizeTags } = entityTagsMap;
  const { programNameTagName } = entityTagNames;

  let projectError;
  let link = `${getConfig().homeCentralUrl}/o/${workspace?.organisationId}/s/${workspace?.cloudId}/project/${
    project.key
  }/updates`;
  let unsuitableTags: Tag[] = [];

  if (entityType === Entity.PROGRAM) {
    projectError = onValidationMessage(Entity.PROGRAM);
    link = getProgramLinkByKey(project.key);
  }

  const hasInitiativeTagForSameProgram = doesInitiativeHaveTagForSameProgram(entityTagsMap, programNameTagName);

  if (entityType === Entity.INITIATIVE && (type !== Entity.INITIATIVE || hasInitiativeTagForSameProgram)) {
    projectError = onValidationMessage(Entity.INITIATIVE);
    link = getInitiativeLink(workspace, initiativeTags, project.key);
  }

  if (entityType === Entity.PROJECT && (type !== Entity.PROJECT || isInvalidProject(entityTagsMap, entityTagNames))) {
    projectError = onValidationMessage(Entity.PROJECT, project);
  }

  if (!projectError) {
    unsuitableTags = getUnsuitableTags(type, entityTagsMap, entityTagNames);
    projectError = unsuitableTags.length ? 'Ticket has unsuitable labels' : undefined;
  }

  const shouldShowSizeTag = !projectError && hasToShowSizeTag(type, sizeTags);

  return {
    link,
    projectError,
    unsuitableTags,
    shouldShowSizeTag,
    initiativeTags,
  };
};
