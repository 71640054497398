import { ReactNode, useMemo } from 'react';
import { FeatureGatesInitialization } from '@atlassian/feature-gates-react';
import { FeatureGateEnvironment } from '@atlaskit/feature-gate-js-client';

import { GlobalLoader } from '@shared/GlobalLoader';
import { getEnv, Env } from 'src/config';
import { useUserStore } from '@store/userStore';
import { useWorkspaceStore } from '@store/workspaceStore';

const env = getEnv();

const featureFlagApiKey = env === Env.STAGING ? process.env.STATSIG_STG_API_KEY! : process.env.STATSIG_PROD_API_KEY!;
const featureFlagEnvironment = env === Env.STAGING ? FeatureGateEnvironment.Staging : FeatureGateEnvironment.Production;

export const featureGatesOptions = {
  targetApp: 'global-state_web',
  apiKey: featureFlagApiKey,
  environment: featureFlagEnvironment,
};

type Props = {
  children: ReactNode;
};

export function StatsigProvider({ children }: Props) {
  const [{ user }] = useUserStore();

  const [{ workspace }] = useWorkspaceStore();

  const featureGatesIdentifiers = useMemo(
    () => ({
      atlassianAccountId: user.account_id,
      tenantId: workspace.cloudId,
      atlassianOrgId: workspace.organisationId,
    }),
    [user.account_id, workspace.cloudId, workspace.organisationId]
  );

  return (
    <FeatureGatesInitialization
      options={featureGatesOptions}
      identifiers={featureGatesIdentifiers}
      initializingComponent={<GlobalLoader />}
    >
      {children}
    </FeatureGatesInitialization>
  );
}
