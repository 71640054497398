import { ReactNode } from 'react';
import { Link } from 'react-resource-router';
import { N300, N800 } from '@atlaskit/theme/colors';
import Tooltip from '@atlaskit/tooltip';
import styled, { css } from 'styled-components';

import { Project } from '@queries/GetProjectPageItems';
import { ProjectIcon } from '@tc/ProjectIcon/ProjectIcon';
import { TCLinkWrapper, TCLinkContentWrapper } from '@shared/TCLinkWrapper';
import { ellipsis } from '@tc/UserAvatar/styles';
import { ENABLE_ASSETS } from 'src/featureFlagsKeys';
import { Entity } from 'src/graphql/types';
import { VerifiedIcon } from '@shared/VerifiedIcon';
import { useExperimentValue } from '@hooks/useExperimentValue';

export const CardHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
  flex: 1;
  min-width: 0;
`;

export const CardHeaderInfoContainer = styled.div`
  max-width: 85%;
  display: flex;
  align-items: center;
`;

export const TypeAndNameWrapper = styled.div`
  display: flex;
  height: 32px;
  flex-direction: column;
  margin-left: 8px;
  flex: 1;
  min-width: 0;
`;

export const LinkWrapper = styled.span`
  a {
    display: flex;
    align-items: center;
    color: ${N800};
    font-size: 14px;
    line-height: 16px;
    font-weight: 600;

    svg {
      height: 20px;
      width: 20px;
    }
  }
`;

export const Type = styled.sub`
  font-size: 12px;
  line-height: 16px;
  font-weight: 600;
  color: ${N300};
`;

export const IconContainer = styled.div<{ color?: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 3px;
  ${(props) =>
    props.color &&
    css`
      background: ${(props) => props.color};
    `}
`;

export const CardHeaderActionContainer = styled.div`
  display: flex;
  align-items: flex-end;
`;

export const ProjectName = styled.div`
  line-height: normal;
  ${ellipsis}
`;

type Props = {
  project: Project & { isVerified?: boolean };
  type: string;
  href: string;
  target: '_blank' | '_self';
  renderIcon?: () => ReactNode;
  renderActions?: () => ReactNode;
};

export const CardHeader = ({ project, type, href, target, renderIcon, renderActions }: Props) => {
  const showAssets = useExperimentValue(ENABLE_ASSETS, 'value', false);

  return (
    <CardHeaderContainer>
      <CardHeaderInfoContainer>
        <IconContainer>
          <ProjectIcon {...project.icon} size="medium" projectUuid={project.uuid} />
        </IconContainer>
        <TypeAndNameWrapper>
          <Type>{type}</Type>
          <LinkWrapper>
            <Link href={href} target={target}>
              <ProjectName>
                <Tooltip content={project.name}>
                  <TCLinkWrapper
                    isLabel={type.toLowerCase() !== Entity.INITIATIVE}
                    isIconNeeded={type.toLowerCase() !== Entity.INITIATIVE}
                    content={<TCLinkContentWrapper>{project.name}</TCLinkContentWrapper>}
                  />
                </Tooltip>
              </ProjectName>
              <VerifiedIcon
                content={project.isVerified ? 'Atlassian Milestone' : `Atlassian Initiative`}
                isVerified={!!showAssets && project.isVerified}
              />
              {renderIcon && renderIcon()}
            </Link>
          </LinkWrapper>
        </TypeAndNameWrapper>
      </CardHeaderInfoContainer>
      {renderActions && (
        <CardHeaderActionContainer data-testid="header-traffic-light">{renderActions()}</CardHeaderActionContainer>
      )}
    </CardHeaderContainer>
  );
};
