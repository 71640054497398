import { useCallback, useMemo } from 'react';
import { ProfileCardTrigger, ProfilecardTriggerPosition } from '@atlaskit/profilecard';

import { getConfig } from 'src/config';
import { profileClient } from '@tc/providers';
import { useWorkspaceStore } from '@store/workspaceStore';

interface Props {
  children: JSX.Element;
  position?: ProfilecardTriggerPosition;
  userId: string;
  isShowProfileCard?: boolean;
  isEmbeddedPage?: boolean;
}

const PEOPLE_PROFILE_PATH = '/people/';

export const WrappedWithProfileCard = (props: Props) => {
  const { userId, position, children, isShowProfileCard = true, isEmbeddedPage = false } = props;
  const [{ workspace }] = useWorkspaceStore();

  const profileClickCallback = useCallback(
    (clickedUserId: string) => {
      window.open(
        `${getConfig().homeCentralUrl}/o/${workspace?.organisationId}/people/${clickedUserId}`,
        '_blank',
        'noreferrer'
      );
    },
    [workspace]
  );

  const profileCardTriggerActions = useMemo(
    () => [
      {
        label: 'View profile',
        id: 'view-profile',
        callback() {
          profileClickCallback(userId);
        },
        link: isEmbeddedPage
          ? undefined
          : `${getConfig().homeCentralUrl}/o/${workspace?.organisationId}/people/${userId}`,
      },
    ],
    [profileClickCallback, isEmbeddedPage, workspace.organisationId, userId]
  );
  const onReportingLinesClick = useCallback((user) => profileClickCallback(user.accountIdentifier), [
    profileClickCallback,
  ]);

  return isShowProfileCard ? (
    <ProfileCardTrigger
      resourceClient={profileClient}
      position={position}
      userId={userId}
      actions={profileCardTriggerActions}
      reportingLinesProfileUrl={PEOPLE_PROFILE_PATH}
      onReportingLinesClick={onReportingLinesClick}
    >
      {children}
    </ProfileCardTrigger>
  ) : (
    children
  );
};
