import { MouseEvent, useCallback, ReactNode, useMemo, useState, useRef, useEffect } from 'react';
import { AtlassianNavigation, PrimaryButton } from '@atlaskit/atlassian-navigation';
import { useRouter } from 'react-resource-router';
import styled from 'styled-components';
import { B400, N600 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';

import { SearchDrawer } from '@tc/SearchDrawer';
import { Feedback } from '@shared/Feedback';
import { ProfileAndWorkspaceSwitcher } from '@shared/ProfileAndWorkspaceSwitcher';
import { ENABLE_ASSETS, ENABLE_MILESTONES } from 'src/featureFlagsKeys';
import { CreateButton } from '@shared/CreateButton';
import { routesMap, routesPathMap } from 'src/routes';
import { MOBILE_MODE_WIDTH_THRESHOLD } from 'src/constants';
import { useExperimentValue } from '@hooks/useExperimentValue';

import { Home } from './Home';
import { HelpPanel } from './HelpPanel';

const PrimaryButtonWrapper = styled.div<{ isPathMatch: boolean }>`
  display: flex;
  position: relative;

  a,
  span {
    color: ${(props) => (props.isPathMatch ? token('color.text.selected', B400) : token('color.text.disabled', N600))};
  }

  &:first-child {
    margin-left: 8px;
  }
`;

type NavItemProps = {
  children: ReactNode;
  href: string;
  matches?: string[];
  name: string;
};

const NavbarWrapper = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 250;
`;

const IsHighlightedBar = styled.div`
  position: absolute;
  bottom: 0;
  left: 4px;
  right: 4px;
  height: 3px;
  background-color: ${token('color.text.selected', B400)};
  border-top-left-radius: 1px;
  border-top-right-radius: 1px;
`;

const NavItem = (props: NavItemProps) => {
  const { href, children, name, matches = [] } = props;
  const [routerState, routerActions] = useRouter();

  const handleRedirect = useCallback(
    (event: MouseEvent<Element>) => {
      event.preventDefault();
      if (event.metaKey) {
        window.open(href, '_blank', 'noreferrer');
      } else {
        routerActions.push(href);
      }
    },
    [href, routerActions]
  );

  const isHighlighted = matches.includes(routerState.match.url) || routerState.match.url === href;
  const isPathMatch = props.matches?.includes(routerState.match.path) ?? false;

  return (
    <PrimaryButtonWrapper isPathMatch={isPathMatch}>
      <PrimaryButton
        testId={`primary-button-${name}`}
        isHighlighted={isHighlighted}
        onClick={handleRedirect}
        href={href}
      >
        {children}
      </PrimaryButton>
      {isPathMatch && <IsHighlightedBar />}
    </PrimaryButtonWrapper>
  );
};

const getNavItems = ({ showMilestones }: { showMilestones: boolean | undefined }) => [
  <NavItem
    key="programs"
    name="programs"
    href="/"
    matches={[
      routesPathMap[routesMap.PROGRAMS],
      routesPathMap[routesMap.PROGRAM],
      routesPathMap[routesMap.PROGRAM_REPORT],
      routesPathMap[routesMap.DASHBOARD],
      routesPathMap[routesMap.UNDER_CONSTRUCTION_STATIC],
    ]}
  >
    Programs
  </NavItem>,
  ...(showMilestones
    ? [
        <NavItem
          key="milestones"
          name="milestones"
          href="/milestones"
          matches={[
            routesPathMap[routesMap.MILESTONE],
            routesPathMap[routesMap.MILESTONE_DASHBOARD],
            routesPathMap[routesMap.MILESTONES],
            routesPathMap[routesMap.MILESTONE_REPORT],
          ]}
        >
          Milestones
        </NavItem>,
      ]
    : []),
  <NavItem
    key="initiatives"
    name="initiatives"
    href="/initiatives"
    matches={[
      routesPathMap[routesMap.INITIATIVES],
      routesPathMap[routesMap.INDEPENDENT_INITIATIVE],
      routesPathMap[routesMap.INITIATIVE_REPORT],
      routesPathMap[routesMap.INITIATIVE_DASHBOARD],
    ]}
  >
    Initiatives
  </NavItem>,
  <NavItem
    key="departments"
    name="departments"
    href="/departments"
    matches={[routesPathMap[routesMap.DEPARTMENTS], routesPathMap[routesMap.DEPARTMENT]]}
  >
    Departments
  </NavItem>,
];

const shouldHideNavbar = (routeName: string) =>
  [
    routesMap.EMBED_PROGRAM,
    routesMap.EMBED_INDEPENDENT_INITIATIVE,
    routesMap.EMBED_MILESTONE,
    routesMap.EMBED_DEPARTMENT,
  ].includes(routeName as routesMap);

export const Navigation = () => {
  const [{ route }] = useRouter();
  const showAssets = useExperimentValue(ENABLE_ASSETS, 'value', false);

  const showMilestones = useExperimentValue(ENABLE_MILESTONES, 'value', false);

  const navItems = useMemo(() => getNavItems({ showMilestones }), [showMilestones]);

  const renderSearch = useCallback(() => <SearchDrawer />, []);

  const renderCreateButton = useCallback(() => (showAssets ? null : <CreateButton />), [showAssets]);

  const [isMobileWidth, setIsMobileWidth] = useState(false);

  const containerRef = useRef<HTMLDivElement>(null);

  // useRef prevents this from being recreated on each render
  const resizeObserver = useRef(
    new ResizeObserver((entries: ResizeObserverEntry[]) => {
      if (entries[0].contentRect.width <= MOBILE_MODE_WIDTH_THRESHOLD) {
        setIsMobileWidth(true);
      } else {
        setIsMobileWidth(false);
      }
    })
  );

  useEffect(() => {
    if (containerRef?.current) {
      resizeObserver.current.observe(containerRef.current);
    } else {
      resizeObserver.current.disconnect();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [containerRef?.current, resizeObserver.current]);

  const renderHelper = useCallback(() => <HelpPanel isMobileWidth={isMobileWidth} />, [isMobileWidth]);

  if (shouldHideNavbar(route.name)) {
    return null;
  }

  return (
    <NavbarWrapper innerRef={containerRef}>
      <AtlassianNavigation
        key={navItems.length}
        label="site"
        primaryItems={navItems}
        renderCreate={renderCreateButton}
        renderProductHome={Home}
        renderSearch={renderSearch}
        renderHelp={renderHelper}
        renderProfile={ProfileAndWorkspaceSwitcher}
      />
      <Feedback />
    </NavbarWrapper>
  );
};
