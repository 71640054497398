import Tabs, { TabList, TabPanel } from '@atlaskit/tabs';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Query, useRouter } from 'react-resource-router';
import styled from 'styled-components';

import { useAnalytics } from '@hooks/useAnalytics';

export enum ContentTabName {
  ABOUT = 'about',
  UPDATES = 'updates',
  RISKS = 'risks',
}
export interface TabData {
  id: string;
  headingComponent: JSX.Element;
  bodyComponent: JSX.Element;
}

export const TabsWrapper = styled.div`
  div[role='tabpanel'] > div {
    width: 100%;
  }
`;

export type TabEntityType = 'program' | 'initiative' | 'update';

export type ContentTabsProps = {
  tabs: TabData[];
  tabType: TabEntityType;
  pageBaseUrlPath?: string;
  isEmbedView?: boolean;
  embedPage?: string;
  page?: string;
};

const getTabIdFromName = (tabName: string | null | undefined) => tabName ?? undefined;

const findTabIndex = (tabs: TabData[], selectedTabId: string) =>
  tabs.findIndex((tab: TabData) => tab.id === selectedTabId);

export const ContentTabs = ({ tabs, tabType, pageBaseUrlPath, isEmbedView, embedPage, page }: ContentTabsProps) => {
  const [routerStore, { push, pushTo }] = useRouter();
  const [index, setIndex] = useState<number>(1);
  const [query, setQuery] = useState<Query>({});
  const { tabName } = routerStore.match.params;
  const tName = tabType === 'update' ? tabs[index].id : tabName;
  const { sendScreenEvent } = useAnalytics();

  useEffect(() => {
    const embedTabType = tabName ? tabName : 'default';

    isEmbedView &&
      embedPage &&
      sendScreenEvent({
        name: embedPage,
        attributes: {
          selectedTab: embedTabType,
        },
      });
  }, [embedPage, isEmbedView, sendScreenEvent, tabName]);

  useEffect(() => {
    if (isEmbedView || !page) {
      return;
    }

    sendScreenEvent({
      name: page,
      attributes: {
        selectedTab: tabName ? tabName : 'default',
      },
    });
  }, [isEmbedView, page, sendScreenEvent, tabName]);

  const selectedTabId = useMemo(() => getTabIdFromName(tName), [tName]);

  const setTabIndex = useCallback(
    (index: number) => {
      if (Object.keys(routerStore.query).length) {
        setQuery(routerStore.query);
      }

      if (pageBaseUrlPath && tabs[index].id === ContentTabName.UPDATES && Object.keys(query).length) {
        pushTo({ ...routerStore.route, path: `${pageBaseUrlPath}/${tabs[index].id}` }, { query });
        setQuery(routerStore.query);
      } else if (pageBaseUrlPath) {
        push(`${pageBaseUrlPath}/${tabs[index].id}`);
      } else {
        setIndex(index);
      }
    },
    [routerStore.query, routerStore.route, pageBaseUrlPath, tabs, query, pushTo, push]
  );

  const getTabIndexOrDefault = useCallback(
    (tabId: string | undefined | null) => {
      const tabIndex = tabId ? findTabIndex(tabs, tabId) : -1;

      if (tabIndex >= 0) {
        return tabIndex;
      } else {
        return findTabIndex(tabs, ContentTabName.UPDATES);
      }
    },
    [tabs]
  );

  return (
    <TabsWrapper>
      <Tabs selected={getTabIndexOrDefault(selectedTabId)} onChange={setTabIndex} id={tabType}>
        <TabList>{tabs.map((tab: TabData) => tab.headingComponent)}</TabList>
        {tabs.map((tab: TabData) => (
          <TabPanel key={tab.id}>{tab.bodyComponent}</TabPanel>
        ))}
      </Tabs>
    </TabsWrapper>
  );
};
