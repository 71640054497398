import { useCallback } from 'react';
import styled from 'styled-components';

import { StatusSummaryBadges, SummaryItemTypeToCountMap } from '@shared/StatusSummaryBadges';
import { CardHeader } from '@shared/CardHeader';
import { createLink } from '@shared/utils/project';
import { WarningIconWrapper } from '@shared/Header/Header';
import { Initiative } from '@program/children/utils/program';
import { useWarningIcon } from '@hooks/useWarningIcon';
import { AriaLabelWarningIcon, TooltipContentWarningIcon } from 'src/constants';
import { useWorkspaceStore } from '@store/workspaceStore';

type Props = {
  initiative: Initiative;
  summaryItemTypeToCountMap?: SummaryItemTypeToCountMap;
  isProjectsLoading?: boolean;
  isMilestone?: boolean;
};

export const WarningWrapper = styled(WarningIconWrapper)`
  align-items: center;
  margin-left: 0;
`;

export const InitiativeCardHeader = ({
  initiative,
  summaryItemTypeToCountMap = {},
  isProjectsLoading,
  isMilestone,
}: Props) => {
  const renderWarningIcon = useWarningIcon(
    initiative.isDuplicated,
    TooltipContentWarningIcon.DUPLICATED_INITIATIVE,
    AriaLabelWarningIcon.DUPLICATED_INITIATIVE
  );
  const [{ workspace }] = useWorkspaceStore();

  const renderActions = useCallback(
    () => (isProjectsLoading ? null : <StatusSummaryBadges summaryItemTypeToCountMap={summaryItemTypeToCountMap} />),
    [isProjectsLoading, summaryItemTypeToCountMap]
  );

  return (
    <CardHeader
      project={initiative}
      type={isMilestone ? 'Milestone' : 'Initiative'}
      {...createLink(initiative, workspace)}
      renderIcon={renderWarningIcon}
      renderActions={renderActions}
    />
  );
};
