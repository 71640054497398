import { useCallback } from 'react';
import { useFlags } from '@atlaskit/flag';
import { FeedbackForm } from '@atlaskit/feedback-collector';
import EditorWarningIcon from '@atlaskit/icon/glyph/editor/warning';
import CheckCircleIcon from '@atlaskit/icon/glyph/check-circle';
import { G300, Y300 } from '@atlaskit/theme/colors';

import { jiraServiceDeskClient } from 'src/clients/JSDClient';
import { useUserStore } from 'src/store/userStore';
import { useHelp } from '@tc/Navigation/HelpPanel/useHelp';

type Error = {
  friendlyReason?: {
    body?: string;
  };
};
const CAN_BE_CONTACTED_VALUE_ID = '16026';
const ENROL_IN_RESEARCH_VALUE_ID = '16027';

enum QuestionType {
  QUESTION = 'question',
  COMMENT = 'comment',
  BUG = 'bug',
  SUGGESTION = 'suggestion',
}
export const questionTypeToId = {
  [QuestionType.BUG]: '12737',
  [QuestionType.SUGGESTION]: '12738',
  [QuestionType.QUESTION]: '12739',
  [QuestionType.COMMENT]: '12740',
};
enum CustomFields {
  CUSTOMER_NAME_FIELD_ID = 'customfield_12922',
  CAN_BE_CONTACTED_FIELD_ID = 'customfield_12923',
  ENROL_IN_RESEARCH_FIELD_ID = 'customfield_12924',
}
type FeedbackFormProps = {
  canBeContacted: boolean;
  description: string;
  enrollInResearchGroup: boolean;
  type: string;
};
export const Feedback = () => {
  const { isOpenFeedbackPopup, setIsOpenFeedbackPopup } = useHelp();
  const [{ user }] = useUserStore();
  const { showFlag } = useFlags();
  const name = user?.name;
  const email = user?.email;

  const toggleVisibilityHelper = useCallback(() => setIsOpenFeedbackPopup(!isOpenFeedbackPopup), [
    isOpenFeedbackPopup,
    setIsOpenFeedbackPopup,
  ]);

  const showFlagDependingOnResponse = useCallback(
    async (response: Response) => {
      if (response.ok) {
        showFlag({
          title: 'Thanks',
          description: 'Your valuable feedback helps us continually improve our products.',
          icon: <CheckCircleIcon primaryColor={G300} label="check icon" />,
          isAutoDismiss: true,
        });
      } else {
        const error: Error = await response.json();

        showFlag({
          title: 'Oops',
          description: error.friendlyReason?.body
            ? error.friendlyReason.body
            : 'Something went wrong. Could you write feedback again?',
          icon: <EditorWarningIcon size="medium" primaryColor={Y300} label="Info" />,
          isAutoDismiss: true,
        });
      }
    },
    [showFlag]
  );
  const sendFeedbackData = useCallback(
    (data: FeedbackFormProps) => {
      const summary = data.description.replaceAll(/\n/g, ' ').slice(0, 254);
      const fields: Array<{ id: string; value: string | Array<{ id: string }> }> = [
        { id: 'summary', value: summary },
        { id: 'description', value: data.description },
        { id: 'email', value: email },
        { id: CustomFields.CUSTOMER_NAME_FIELD_ID, value: name },
      ];

      if (data.canBeContacted) {
        fields.push({ id: CustomFields.CAN_BE_CONTACTED_FIELD_ID, value: [{ id: CAN_BE_CONTACTED_VALUE_ID }] });
      }
      if (data.enrollInResearchGroup) {
        fields.push({ id: CustomFields.ENROL_IN_RESEARCH_FIELD_ID, value: [{ id: ENROL_IN_RESEARCH_VALUE_ID }] });
      }

      return jiraServiceDeskClient.submitFeedback({
        requestTypeId: questionTypeToId[data.type as QuestionType],
        fields,
      });
    },
    [email, name]
  );

  const onSubmitHelper = useCallback(
    async (data: FeedbackFormProps) => {
      const response = await sendFeedbackData(data);

      await showFlagDependingOnResponse(response);
      toggleVisibilityHelper();
    },
    [sendFeedbackData, showFlagDependingOnResponse, toggleVisibilityHelper]
  );

  return (
    <div>{isOpenFeedbackPopup && <FeedbackForm onSubmit={onSubmitHelper} onClose={toggleVisibilityHelper} />}</div>
  );
};
