import { getConfig } from 'src/config';
import { Project } from '@queries/SearchProjects';
import { Project as GqlProject } from '@queries/GetProjectPageItems';
import { Initiative, Program } from '@program/children/utils/program';
import { isInitiative } from '@shared/WeeklySummary/WeeklySummaryModalRow';
import { Tags } from 'src/graphql/types';
import { DepartmentProject as FetchedDepartmentProject } from '@queries/GetDepartmentProjects';
import {
  DEPARTMENT_NAME_TAG_REGEXP,
  DEPARTMENT_NAME_WITH_PILLAR_TAG_REGEXP,
  DepartmentProject,
} from '@department/children/utils/department';
import { ProjectType } from '@department/children/utils/departmentTable';
import { Department } from 'src/store/departmentStore';
import { INDEPENDENT_INITIATIVE_TAG_PREFIX } from 'src/constants';
import { GetProjectPageItemsQuery } from '@generated/GetProjectPageItems';
import { ProjectDependencyRelationship } from '@generated/graphql';
import { UserWorkspaceDetails } from '@store/workspaceStore';

type getProjectUrlProps = {
  isDuplicated: boolean;
  key: string;
  uuid: string;
  tags: Project['tags'];
  isProgram: boolean;
};

export const findSizeTagUuid = (tags: Project['tags'], sizeTagToCompare: Tags): string =>
  tags.edges.find(({ node: { name } }) => name === sizeTagToCompare)?.node.uuid;

export const getProjectUrl = (
  { isDuplicated, key, uuid, tags, isProgram }: getProjectUrlProps,
  organisationId?: string,
  cloudId?: string
): string => {
  if (!isDuplicated) {
    return `${getConfig().homeCentralUrl}/o/${organisationId}/s/${cloudId}/project/${key}/updates`;
  }

  const link = new URL(`${getConfig().homeCentralUrl}/o/${organisationId}/s/${cloudId}/projects`);
  const sizeTagToCompare = isProgram ? Tags.program : Tags.initiative;

  const sizeTagUuid = findSizeTagUuid(tags, sizeTagToCompare);

  const tqlQueryParam = `(archived = false) AND ((label = ${sizeTagUuid}) AND (label = ${uuid}))`;

  link.searchParams.append('tql', tqlQueryParam);

  return link.toString();
};

export const canModifyProject = (project: Program | Initiative | Project, aaid: string) =>
  project.members.edges.some(({ node }) => aaid === node.aaid);

export const splitTag = (initiativeTagName: string) => {
  const extractProgramAndInitiativeNameFromTagName = initiativeTagName.split('--');

  const [, programName, initiativeName] = extractProgramAndInitiativeNameFromTagName;

  // TODO: should be refactored after https://pi-dev-sandbox.atlassian.net.atl.zbizdev.zengel.myshn.net/browse/GST-736 merge
  if (!initiativeName) {
    return { initiativeName: programName };
  }

  return {
    programName,
    initiativeName,
  };
};

export const createLink = (
  project: Initiative | GqlProject | FetchedDepartmentProject | DepartmentProject,
  workspace: UserWorkspaceDetails,
  shouldOpenInNewWindow?: boolean
) => {
  const { homeCentralUrl } = getConfig();
  let href: string;
  let target: '_self' | '_blank';

  if (isInitiative(project) && (project as Initiative).isDuplicated) {
    const url = new URL(`${homeCentralUrl}/o/${workspace?.organisationId}/s/${workspace?.cloudId}/projects`);

    const sizeInitiativeTagUuid = findSizeTagUuid(project.tags, Tags.initiative);

    const tqlQueryParam = `(archived = false) AND ((label = ${sizeInitiativeTagUuid}) AND (label = ${
      (project as Initiative).initiativeTag.uuid
    }))`;

    url.searchParams.append('tql', tqlQueryParam);
    href = url.toString();
    target = '_blank';
  } else if (isInitiative(project)) {
    const { programName, initiativeName } = splitTag((project as Initiative).initiativeTag.name);

    href = programName
      ? `/program/${programName}/${initiativeName}`
      : (project as Initiative).isVerified
      ? `/milestone/${project.key}`
      : `/initiative/${initiativeName}`;
    target = shouldOpenInNewWindow ? '_blank' : '_self';
  } else if ('type' in project && project.type === ProjectType.MILESTONE) {
    href = `/milestone/${project.key}`;
    target = shouldOpenInNewWindow ? '_blank' : '_self';
  } else if ('type' in project && (project.type === ProjectType.INITIATIVE || project.type === ProjectType.PROGRAM)) {
    href = project.url;
    target = '_self';
  } else {
    href = `${homeCentralUrl}/o/${workspace?.organisationId}/s/${workspace?.cloudId}/project/${project.key}/updates`;
    target = '_blank';
  }

  return {
    href,
    target: target,
  };
};

export const isValidDepartmentTag = (departmentLabels: string[], tag: string) => {
  if (DEPARTMENT_NAME_TAG_REGEXP.test(tag)) {
    return departmentLabels.includes(tag);
  }

  if (DEPARTMENT_NAME_WITH_PILLAR_TAG_REGEXP.test(tag)) {
    const [prefix, dept] = tag.split('--');

    return departmentLabels.includes(`${prefix}--${dept}`);
  }

  return false;
};

export const filterDepartmentOrDepartmentWithPillarTags = (tags: Project['tags'], departments: Department[]) => {
  const departmentLabels = departments.map(({ label }) => label);

  return {
    edges: tags.edges.filter(({ node: { name } }) => isValidDepartmentTag(departmentLabels, name)),
  };
};

export const isProjectContainTagName = (project: Project) => {
  if (!project) {
    return false;
  }

  return project.tags.edges.some(({ node: { name } }) => name.includes(INDEPENDENT_INITIATIVE_TAG_PREFIX));
};

export const getDependedOnProjectKeys = (
  dependencies: GetProjectPageItemsQuery['projectTql']['edges'][0]['node']['dependencies']
) => {
  if (!dependencies) return [];

  return dependencies.edges
    .filter((edge) => edge.node.linkType === ProjectDependencyRelationship.DependsOn)
    .map((edge) => edge.node.outgoingProject.key);
};
