import { Link } from 'react-resource-router';
import { SimpleTag, TagColor } from '@atlaskit/tag';
import TagGroup from '@atlaskit/tag-group';
import styled from 'styled-components';
import { MouseEvent, ReactNode, useCallback, useState } from 'react';
import ShortcutIcon from '@atlaskit/icon/glyph/shortcut';
import Tooltip from '@atlaskit/tooltip';

import { getConfig } from 'src/config';
import { Project } from '@queries/GetProjectPageItems';
import { withLoading } from 'src/hoc/withLoading';
import { ellipsis } from '@tc/UserAvatar/styles';
import { getStringHash } from 'src/utils/getStringHash';
import { useWorkspaceStore } from '@store/workspaceStore';

import { LabelsLoader } from './loaders/LabelsLoader';

export const TagWrapper = styled.span<{ width?: number; tagColor?: string }>`
  display: flex;
  align-items: center;
  a {
    text-decoration: none !important;
  }

  width: ${({ width }) => (width ? `${width}px` : `auto`)};
  min-width: auto;

  & > span {
    background-color: ${(props) => props.tagColor};
  }

  &:hover .shortcut-icon-wrapper {
    display: block;
  }
`;

const LinkWrapper = styled.span`
  display: flex;
  align-items: center;
  ${ellipsis}
  cursor: pointer;
`;

const ShortcutIconWrapper = styled.span`
  display: none;
`;

type Props = {
  tags: Project['tags'];
};

type LabelProps = {
  name: string;
  uuid: string;
};

const tagColors = ['greenLight', 'tealLight', 'blueLight', 'purpleLight', 'redLight', 'yellowLight'];

const tagHtmlColor: Record<string, string> = {
  greenLight: 'rgb(186, 243, 219)',
  tealLight: 'rgb(121, 226, 242)',
  blueLight: 'rgb(204, 224, 255)',
  purpleLight: 'rgb(223, 216, 253)',
  redLight: 'rgb(255, 210, 204)',
  yellowLight: 'rgb(255, 226, 189)',
};

export const getTagColor = (tagName: string) =>
  tagColors[Math.abs(getStringHash(tagName)) % tagColors.length] as TagColor;
export const getTagHtmlColor = (tagName: string) => tagHtmlColor[getTagColor(tagName) as string];

const LinkComponent = ({ name, children, ...props }: { name: string; children: ReactNode }) => (
  <LinkWrapper>
    <Link data-testid={`label-${name}`} target="_blank" {...props}>
      {children}
    </Link>
    <ShortcutIconWrapper className="shortcut-icon-wrapper">
      <Link target="_blank" {...props}>
        <ShortcutIcon label="" size="small" />
      </Link>
    </ShortcutIconWrapper>
  </LinkWrapper>
);

const Label = ({ name, uuid }: LabelProps) => {
  const [labelWidth, setLabelWidth] = useState(0);

  const handleMouseEnter = useCallback((e: MouseEvent<HTMLSpanElement>) => {
    const target = e.target as HTMLSpanElement;
    const width = target.clientWidth;

    setLabelWidth(width);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setLabelWidth(0);
  }, []);
  const [{ workspace }] = useWorkspaceStore();

  return (
    <Tooltip content={`# ${name}`} position="bottom-start" key={uuid}>
      <TagWrapper onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} width={labelWidth}>
        <SimpleTag
          text={`# ${name}`}
          color={getTagColor(name)}
          href={`${getConfig().homeCentralUrl}/o/${workspace?.organisationId}/s/${workspace?.cloudId}/tag/${uuid}/work`}
          linkComponent={({ children, ...props }) => <LinkComponent name={name} children={children} {...props} />}
        />
      </TagWrapper>
    </Tooltip>
  );
};

export const LabelsComponent = ({ tags }: Props) => (
  <TagGroup>
    {tags.edges.map(({ node: { name, uuid } }) => (
      <Label key={uuid} name={name} uuid={uuid} />
    ))}
  </TagGroup>
);

export const Labels = withLoading(LabelsComponent, LabelsLoader);
