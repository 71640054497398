import { useEffect, useMemo } from 'react';
import { Router, RouteComponent, createBrowserHistory } from 'react-resource-router';
import styled from 'styled-components';
import { useFlags } from '@atlaskit/flag';
import ErrorIcon from '@atlaskit/icon/glyph/error';
import { R400 } from '@atlaskit/theme/colors';

import { useUserStore } from '@store/userStore';
import { Navigation } from '@tc/Navigation';
import { useHelp } from '@tc/Navigation/HelpPanel/useHelp';
import { useExperimentValue } from '@hooks/useExperimentValue';

import { getRoutes } from './routes';
import { ENABLE_ASSETS } from './featureFlagsKeys';

const Content = styled.div`
  padding-top: 56px;
`;

const RightPanelWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  position: relative;
  z-index: 200;

  > div > div {
    width: 256px !important;
  }
`;

const history = createBrowserHistory();

export const RouterWrapper = () => {
  const [{ requestError }, { setRequestError }] = useUserStore();
  const { showFlag } = useFlags();
  const { isOpen: isHelpOpen } = useHelp();
  const isAssetsEnable = useExperimentValue(ENABLE_ASSETS, 'value', false);

  const routes = useMemo(() => {
    if (isAssetsEnable === undefined) {
      return [];
    }

    return getRoutes({ isAssetsEnable });
  }, [isAssetsEnable]);

  useEffect(() => {
    requestError &&
      showFlag({
        title: `Request error ${requestError.code || ''}`,
        description: requestError?.message,
        icon: <ErrorIcon label="Error" primaryColor={R400} size="medium" />,
        isAutoDismiss: true,
        onDismissed: () => setRequestError(null),
      });
  }, [requestError, showFlag, setRequestError]);

  if (isAssetsEnable === undefined) {
    return null;
  }

  return (
    <Router routes={routes} history={history} resourceContext={{ isAssetsEnable }}>
      <Navigation />
      {isHelpOpen && (
        <RightPanelWrapper>
          <div id="help-panel" />
        </RightPanelWrapper>
      )}
      <Content>
        <RouteComponent />
      </Content>
    </Router>
  );
};
