import { useCallback, useMemo, useState } from 'react';
import { Link } from 'react-resource-router';
import Button from '@atlaskit/button';
import ChevronDownIcon from '@atlaskit/icon/glyph/chevron-down';
import ChevronUpIcon from '@atlaskit/icon/glyph/chevron-up';
import Tooltip from '@atlaskit/tooltip';

import { ProjectIcon } from '@tc/ProjectIcon/ProjectIcon';
import { Project } from '@queries/GetProjectPageItems';
import { Initiative } from '@program/children/utils/program';
import { ProjectUpdateCard } from '@tc/UpdateCard/ProjectUpdateCard';
import { getSummaryItemTypeToCountMap } from '@shared/utils/statusSummary';
import { createLink } from '@shared/utils/project';
import { ProjectWithUpdates } from '@shared/utils/weeklySummary';
import { DepartmentProject } from '@queries/GetDepartmentProjects';
import { ProjectEmptyUpdateCard } from '@shared/ProjectEmptyUpdateCard';
import { getEntityType } from '@shared/utils/entity';
import { TCLinkContentWrapper, TCLinkWrapper } from '@shared/TCLinkWrapper';
import { ProjectType } from '@department/children/utils/departmentTable';
import { Status } from '@shared/WeeklySummary/WeeklySummaryStatus';
import {
  ButtonWrapper,
  CardHeaderActionContainer,
  CardHeaderContainer,
  CardHeaderInfoContainer,
  IconContainer,
  LinkWrapper,
  ProjectUpdateCardWrapper,
  Type,
  TypeAndNameWrapper,
  Wrapper,
} from '@shared/WeeklySummary/styles';
import { useWarningIcon } from '@hooks/useWarningIcon';
import { AriaLabelWarningIcon, TooltipContentWarningIcon } from 'src/constants';
import { StyledDiv } from '@shared/ProjectRow';
import { VerifiedIcon } from '@shared/VerifiedIcon';
import { ENABLE_ASSETS } from 'src/featureFlagsKeys';
import { StatusOrPhaseLozenge } from '@tc/StatusOrPhaseLozenge';
import { ProjectStateValue } from '@generated/graphql';
import { useExperimentValue } from '@hooks/useExperimentValue';
import { useWorkspaceStore } from '@store/workspaceStore';

export type Props = {
  project: ProjectWithUpdates & { isVerified?: boolean };
  isNoChangeSection: boolean;
  isNoUpdateWidget: boolean;
  isNoChange?: boolean;
  isDepartment?: boolean;
  isPendingWidget?: boolean;
};

export const isInitiative = (project: Initiative | Project | DepartmentProject) => 'projects' in project;

export const WeeklySummaryModalRow = ({
  project,
  isNoChangeSection,
  isNoUpdateWidget,
  isNoChange,
  isDepartment,
  isPendingWidget,
}: Props) => {
  const [isProjectUpdateCardVisible, setIsProjectUpdateCardVisible] = useState<boolean>(false);
  const [{ workspace }] = useWorkspaceStore();

  const { targetWeekUpdate, weekBeforeTargetWeekUpdate } = project;

  const isUpdated = targetWeekUpdate && (!targetWeekUpdate.missedUpdate || targetWeekUpdate.editDate);
  const hasUpdates = targetWeekUpdate || weekBeforeTargetWeekUpdate;

  const isDisable = isPendingWidget && !hasUpdates;

  const isProjectIsInitiative = isInitiative(project);

  const summaryItemTypeToCountMap = useMemo(
    () => (isProjectIsInitiative ? getSummaryItemTypeToCountMap((project as Initiative).projects) || {} : undefined),
    [isProjectIsInitiative, project]
  );

  const toggleCollapsed = useCallback(() => {
    setIsProjectUpdateCardVisible((prevState) => !prevState);
  }, []);

  const extendedProject = project as ProjectWithUpdates & { type: string; isVerified?: boolean };
  const isIconNeededDepartment = extendedProject.type === ProjectType.PROJECT;
  const isIconNeeded = isDepartment ? isIconNeededDepartment : !isProjectIsInitiative;

  const extendedInitiative = project as ProjectWithUpdates & { isDuplicated: boolean };
  const isDuplicatedInitiative = extendedInitiative.isDuplicated;
  const renderWarningIcon = useWarningIcon(
    isDuplicatedInitiative,
    TooltipContentWarningIcon.DUPLICATED_INITIATIVE,
    AriaLabelWarningIcon.DUPLICATED_INITIATIVE
  );

  const showAssets = useExperimentValue(ENABLE_ASSETS, 'value', false);

  return (
    <Wrapper>
      <CardHeaderContainer>
        <CardHeaderInfoContainer isStatusChanged={isNoChange}>
          <ButtonWrapper>
            <Button
              spacing="none"
              appearance="subtle"
              isDisabled={isDisable}
              iconBefore={
                isProjectUpdateCardVisible ? <ChevronUpIcon label="Collapse" /> : <ChevronDownIcon label="Expand" />
              }
              onClick={toggleCollapsed}
            />
          </ButtonWrapper>
          <IconContainer>
            <ProjectIcon {...project.icon} size="medium" projectUuid={project.uuid} />
          </IconContainer>
          <TypeAndNameWrapper>
            <Type>{getEntityType(project, isProjectIsInitiative)}</Type>
            <StyledDiv>
              <LinkWrapper>
                <Link {...createLink(project, workspace)}>
                  <Tooltip content={project.name} position="bottom-start">
                    <TCLinkWrapper
                      isLabel={isIconNeeded}
                      isIconNeeded={isIconNeeded}
                      content={<TCLinkContentWrapper>{project.name}</TCLinkContentWrapper>}
                    />
                  </Tooltip>
                </Link>
              </LinkWrapper>
              <VerifiedIcon
                content={
                  extendedProject.type === ProjectType.PROGRAM
                    ? 'Atlassian Program'
                    : project.isVerified
                    ? 'Atlassian Milestone'
                    : 'Atlassian Initiative'
                }
                isVerified={!!showAssets && project && project.isVerified}
              />
              {renderWarningIcon()}
            </StyledDiv>
          </TypeAndNameWrapper>
        </CardHeaderInfoContainer>
        <CardHeaderActionContainer>
          {isDisable ? (
            <StatusOrPhaseLozenge
              state={{
                label: 'Pending',
                value: ProjectStateValue.Pending,
              }}
            />
          ) : (
            <Status project={project} isNoChangeSection={isNoChangeSection} isNoUpdateWidget={isNoUpdateWidget} />
          )}
        </CardHeaderActionContainer>
      </CardHeaderContainer>
      {isProjectUpdateCardVisible && targetWeekUpdate && isUpdated && (
        <ProjectUpdateCardWrapper>
          <ProjectUpdateCard
            isShowProjectStatus={false}
            isTargetWeekUpdate={true}
            update={targetWeekUpdate}
            project={project}
            summaryItemTypeToCountMap={summaryItemTypeToCountMap}
            isUserAvatarFixedWidth={true}
          />
        </ProjectUpdateCardWrapper>
      )}
      {isProjectUpdateCardVisible && !isUpdated && hasUpdates && (
        <ProjectEmptyUpdateCard
          isExpanded={true}
          isShowProjectStatus={false}
          isTargetWeekUpdate={true}
          targetWeekUpdate={targetWeekUpdate}
          project={project}
          summaryItemTypeToCountMap={summaryItemTypeToCountMap}
          isUserAvatarFixedWidth={true}
        />
      )}
    </Wrapper>
  );
};
