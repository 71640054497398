import styled from 'styled-components';

import { ENABLE_XMAS_LOGO } from 'src/featureFlagsKeys';
import { useExperimentValue } from '@hooks/useExperimentValue';

type Props = {
  size: 'small' | 'big';
};

const Hat = styled.div<Props>`
  position: absolute;
  top: ${(props) => (props.size === 'small' ? -8 : -10)}px;
  left: ${(props) => (props.size === 'small' ? 8 : 5)}px;
  width: ${(props) => (props.size === 'small' ? 24 : 28)}px;
  height: ${(props) => (props.size === 'small' ? 24 : 28)}px;
  background: url(/hat.png) 0 0 no-repeat;
  background-size: contain;
  z-index: 1000;
  transform: rotateY(180deg);
`;

export const SantaHat = (props: Props) => {
  const enableXmasLogo = useExperimentValue(ENABLE_XMAS_LOGO, 'value', false);

  return enableXmasLogo ? <Hat {...props} /> : null;
};
