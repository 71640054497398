export enum Env {
  PRODUCTION = 'prod',
  STAGING = 'staging',
}
type Config = typeof config & {
  env: Env;
};
type EnvironmentOverrides = { [env in Env]?: Partial<Config> };

export const getEnv = (): Env => {
  if (window.location.hostname === 'global-state.atlassian.com.atl.zbizdev.zengel.myshn.net') {
    return Env.PRODUCTION;
  }

  return Env.STAGING;
};

const config = {
  homeCentralUrl: 'https://home.stg.atlassian.com',
  authCheckUrl: '/gateway/api/me',
  graphqlUrl: '/gateway/api/watermelon/graphql',
  atlassianAccountUrl: 'https://id.stg.internal.atlassian.com',
  emojiUrl: '/gateway/api/emoji',
  mediaTokensUrl: '/gateway/api/watermelon/media-tokens',
  mediaBaseUrl: 'https://media.staging.atl-paas.net',
  mediaRendererTokenUrl: '/gateway/api/watermelon/media-token',
  mediaUploadTokenUrl: '/gateway/api/watermelon/media-upload-token',
  reactionsUrl: '/gateway/api/watermelon/reactions',
  profileCardUrl: '/gateway/api/directory/graphql',
  directoryServiceUrl: '/gateway/api/directory',
  jiraServiceDeskEmbeddableKey: process.env.JIRA_SERVICE_DESK_EMBEDDABLE_KEY,
  feedbackUrl: 'https://jsd-widget.atlassian.com/api/embeddable',
  gsApiBaseUrl: '/gateway/api/global-state/api',
  tawHelpUrl: 'https://hello.atlassian.net.atl.zbizdev.zengel.myshn.net/wiki/spaces/TAW/pages/567827674/TAW+Phases',
  prodJira: 'https://hello.jira.atlassian.cloud/browse',
  stgJira: 'https://global-state.jira-dev.com/browse',
};

const environmentOverrides: EnvironmentOverrides = {
  prod: {
    homeCentralUrl: 'https://home.atlassian.com',
    atlassianAccountUrl: 'https://id.atlassian.com.atl.zbizdev.zengel.myshn.net',
    mediaBaseUrl: 'https://api.media.atlassian.com.atl.zbizdev.zengel.myshn.net',
  },
};

export function getConfig() {
  const env = getEnv();

  return {
    env,
    ...config,
    ...environmentOverrides[env],
  };
}
