import { Dispatch, SetStateAction, ChangeEvent, useEffect, useCallback, useMemo, useRef } from 'react';
import { useLazyQuery } from '@apollo/client';
import { B100, N30, N300, N20A, B400, N400 } from '@atlaskit/theme/colors';
import debounce from 'lodash.debounce';
import ArrowLeftIcon from '@atlaskit/icon/glyph/arrow-left';
import styled from 'styled-components';

import { getConfig } from 'src/config';
import { BackButtonWrapper, Header, HeaderText, ProjectName } from '@shared/CreationFlow/styles';
import { Project, SEARCH_PROJECTS } from '@queries/SearchProjects';
import { SearchProjectsQuery, SearchProjectsQueryVariables } from '@generated/SearchProjects';
import { LoadingState } from '@tc/LoadingState';
import { ProjectIcon } from '@tc/ProjectIcon/ProjectIcon';
import { Entity } from 'src/graphql/types';
import { useWorkspaceStore } from 'src/store/workspaceStore';
import { TCLinkWrapper } from '@shared/TCLinkWrapper';

const InputContainer = styled.div`
  margin: 20px 20px 24px 20px;
  position: relative;
  display: flex;
  flex-direction: column;
`;

const InputField = styled.input`
  height: 40px;
  font-size: 14px;
  line-height: 20px;
  padding: 10px;
  width: 100%;

  outline: none;
  border: 2px solid ${N30};
  box-sizing: border-box;
  border-radius: 3px;

  &:focus {
    border-color: ${B100};
  }
`;

const ProjectListTitle = styled.p`
  padding: 0 20px;
  margin: 0 0 14px 0;
  color: ${N300};
  font-size: 12px;
  line-height: 16px;
`;

const ProjectList = styled.ul`
  margin: 0 0 14px 0;
  padding: 0;
  list-style-type: none;
`;

const ProjectListItem = styled.li`
  display: flex;
  align-items: center;
  margin: 0;
  padding: 10px 20px;
  cursor: pointer;

  &:hover {
    background: ${N20A};
  }
`;

const Footer = styled.footer`
  display: flex;
  flex-direction: column;
  padding: 0 20px 16px 20px;
`;

const TeamCentralLink = styled(ProjectName)`
  cursor: pointer;
  margin-left: 0;
  & > div {
    color: ${B400};
  }
`;

const FooterDescription = styled(ProjectName)`
  margin-left: 0;
  color: ${N300};
`;

const Label = styled.p`
  color: ${N300};
  font-size: 12px;
  line-height: 16px;
  text-align: left;
  margin-bottom: 4px;
`;

type Props = {
  type: Entity;
  nameState: [string, Dispatch<SetStateAction<string>>];
  projectsState: [Project[], Dispatch<SetStateAction<Project[]>>];
  scheduleUpdate: () => void;
  setInitialFocusRef: Dispatch<SetStateAction<HTMLElement | null>>;
  onSelectProject: (project: Project | null) => void;
  projectsListHeaderText: string;
  handleBackToMainMenu?: () => void;
};

const typeToFooterDescriptionTextMap = {
  [Entity.PROGRAM]: "Can't see your program?",
  [Entity.MILESTONE]: "Can't see your milestone?",
  [Entity.INITIATIVE]: "Can't see your initiative?",
  [Entity.PROJECT]: "Can't see your project?",
};

const typeToInputLabelTextMap = {
  [Entity.PROGRAM]: 'Find your program project in Atlas',
  [Entity.MILESTONE]: 'Find your milestone project in Atlas',
  [Entity.INITIATIVE]: 'Find your initiative project in Atlas',
  [Entity.PROJECT]: 'Find your project in Atlas',
};

const LIMIT = 5;

export const ProjectListWithSearch = ({
  type,
  nameState,
  projectsState,
  scheduleUpdate,
  setInitialFocusRef,
  onSelectProject,
  projectsListHeaderText,
  handleBackToMainMenu,
}: Props) => {
  const [name, setName] = nameState;
  const [projects, setProjects] = projectsState;

  const [{ workspace }] = useWorkspaceStore();

  const prevName = useRef<string | null>(name || null);

  const { homeCentralUrl } = getConfig();

  const urlPattern = useMemo(() => new RegExp(`^${homeCentralUrl}.*/project/(?<key>[a-zA-Z0-9-]+)`), [homeCentralUrl]);

  const getUrlKey = useCallback(
    (searchValue: string): string | undefined => urlPattern.exec(searchValue)?.groups?.key,
    [urlPattern]
  );

  const [searchProjects, { data, loading }] = useLazyQuery<SearchProjectsQuery, SearchProjectsQueryVariables>(
    SEARCH_PROJECTS
  );

  const debounceSearchProjects = useMemo(() => debounce(searchProjects, 150), [searchProjects]);

  useEffect(() => {
    scheduleUpdate();
  }, [projects, scheduleUpdate]);

  useEffect(() => {
    const projects = data?.projectSearch.edges.map(({ node }) => node);

    if (projects) {
      setProjects(projects);
    }
  }, [data, setProjects]);

  useEffect(() => {
    if (prevName.current !== name) {
      const projectKey = getUrlKey(name);

      debounceSearchProjects({ variables: { q: projectKey ?? name, first: LIMIT, workspaceId: workspace.id } });
      scheduleUpdate();
    }

    prevName.current = name;
  }, [name, debounceSearchProjects, scheduleUpdate, workspace.id, getUrlKey]);

  const handleNameChange = useCallback((e: ChangeEvent<HTMLInputElement>) => setName(e.target.value), [setName]);

  const openTeamCentral = useCallback(
    () => window.open(getConfig().homeCentralUrl, '_blank', 'noopener, noreferrer'),
    []
  );

  return (
    <div>
      <Header>
        {handleBackToMainMenu && (
          <BackButtonWrapper onClick={handleBackToMainMenu}>
            <ArrowLeftIcon label="back-button" primaryColor={N400} />
          </BackButtonWrapper>
        )}

        <HeaderText>{projectsListHeaderText}</HeaderText>
      </Header>
      <InputContainer>
        <Label>{typeToInputLabelTextMap[type]}</Label>
        <InputField innerRef={setInitialFocusRef} placeholder="Project name" value={name} onChange={handleNameChange} />
      </InputContainer>
      {!!projects.length && <ProjectListTitle>Atlas Projects suggestions:</ProjectListTitle>}
      {!projects.length && loading ? (
        <LoadingState />
      ) : (
        <ProjectList>
          {projects.map((project) => (
            <ProjectListItem key={project.uuid} onClick={() => onSelectProject(project)}>
              <ProjectIcon {...project.icon} size={'small'} projectUuid={project.uuid} />
              <ProjectName>{project.name}</ProjectName>
            </ProjectListItem>
          ))}
        </ProjectList>
      )}
      <Footer>
        <FooterDescription>{typeToFooterDescriptionTextMap[type]}</FooterDescription>
        <TeamCentralLink onClick={openTeamCentral}>
          <TCLinkWrapper content="Create new project in Atlas" />
        </TeamCentralLink>
      </Footer>
    </div>
  );
};
