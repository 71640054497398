import { useMemo } from 'react';
import Avatar from '@atlaskit/avatar';
import Lozenge from '@atlaskit/lozenge';
import { ProfilecardTriggerPosition } from '@atlaskit/profilecard';
import { Link } from 'react-resource-router';

import { getConfig } from 'src/config';
import { WrappedWithProfileCard } from '@tc/UserAvatar/WrappedWithProfileCard';
import {
  AvatarName,
  AvatarWrapper,
  BorderlessAvatar,
  Description,
  InvitedLozengeWrapper,
  NameAndDescription,
  Size,
} from '@tc/UserAvatar/styles';
import { useWorkspaceStore } from '@store/workspaceStore';

export type UserAvatarProps = {
  aaid?: string | null;
  hideBorder?: boolean;
  name?: string | null;
  jobTitle?: string | null;
  showName?: boolean;
  size: Size;
  url?: string | null;
  linkToProfile?: boolean;
  isPendingUser?: boolean;
  isDeactivatedUser?: boolean;
  profileCardPosition?: ProfilecardTriggerPosition;
  className?: string;
  isElementNextToIcon?: boolean;
  avatarMargin?: string;
  isShowProfileCard?: boolean;
  isEmbeddedPage?: boolean;
};

export const DEACTIVATED_ACCOUNT_STATUS = 'inactive';
export const DEACTIVATED_SUFFIX_TEXT = ' (Deactivated)';
export const isUserDeactivated = (accountStatus?: string | null) => accountStatus === DEACTIVATED_ACCOUNT_STATUS;

export const getAvatarUrl = (url?: string | null, name?: string | null) =>
  url || `https://api.adorable.io/avatars/80/${(name ?? 'Watermelon').replace(' ', '')}.png`;

export const UserAvatar = (props: UserAvatarProps) => {
  const {
    aaid,
    url,
    size = 'medium',
    showName = true,
    hideBorder,
    name,
    jobTitle,
    linkToProfile = true,
    isPendingUser = false,
    isDeactivatedUser = false,
    profileCardPosition,
    avatarMargin,
    isShowProfileCard,
    isEmbeddedPage,
  } = props;
  const src = getAvatarUrl(url, name);
  const AvatarElement = useMemo(() => <Avatar src={src} size={size} borderColor="transparent" />, [src, size]);
  const [{ workspace }] = useWorkspaceStore();

  return (
    <WrappedWithProfileCard
      userId={aaid!}
      position={profileCardPosition}
      isShowProfileCard={isShowProfileCard}
      isEmbeddedPage={isEmbeddedPage}
    >
      <AvatarWrapper className={props.className} avatarMargin={avatarMargin} data-testid="owner-avatar">
        <BorderlessAvatar size={size} hideBorder={hideBorder}>
          {AvatarElement}
        </BorderlessAvatar>

        {showName && (
          <NameAndDescription size={size}>
            {linkToProfile ? (
              <Link
                href={`${getConfig().homeCentralUrl}/o/${workspace?.organisationId}/people/${aaid}`}
                target="_blank"
              >
                {' '}
                <AvatarName size={size}>
                  {name}
                  {isDeactivatedUser && DEACTIVATED_SUFFIX_TEXT}
                </AvatarName>
              </Link>
            ) : (
              <AvatarName size={size}>
                {name}
                {isDeactivatedUser && DEACTIVATED_SUFFIX_TEXT}
              </AvatarName>
            )}
            {jobTitle && <Description>{jobTitle}</Description>}
          </NameAndDescription>
        )}
        {isPendingUser && showName && (
          <InvitedLozengeWrapper>
            <Lozenge>Invited</Lozenge>
          </InvitedLozengeWrapper>
        )}
      </AvatarWrapper>
    </WrappedWithProfileCard>
  );
};
