import ShortcutIcon from '@atlaskit/icon/glyph/shortcut';
import styled, { css } from 'styled-components';

import { ellipsis } from '@tc/UserAvatar/styles';

const Wrapper = styled.div<Style>`
  ${ellipsis};
  display: ${(p) => (p.isLabel ? 'flex' : p.isInline ? 'inline' : 'block')};
  align-items: ${(p) => (p.isLabel ? 'center' : 'none')};

  span[data-testid='external-link-icon'] {
    opacity: 0;
    transition: opacity 0.3s, margin-left 0.3s;
    margin-left: ${(p) => (p.isLabel || p.isElementNextToIcon ? '-16px' : '0')};
  }

  &:hover span[data-testid='external-link-icon'] {
    opacity: 1;
    margin-left: ${(p) => (p.isLabel || p.isInline ? '5px' : '4px')};
  }
`;

export const TCLinkContentWrapper = styled.div<{
  isTruncated?: boolean;
  haveLineHeightNormal?: boolean;
  isUserAvatarFixedWidth?: boolean;
}>`
  padding-right: ${({ isTruncated }) => (isTruncated ? '15px' : '0')};
  ${ellipsis};
  line-height: ${({ haveLineHeightNormal }) => (haveLineHeightNormal ? 'normal' : 'auto')};

  ${({ isUserAvatarFixedWidth }) =>
    isUserAvatarFixedWidth &&
    css`
      max-width: 110px;
    `}
`;

type Style = {
  isInline?: boolean;
  isLabel?: boolean;
  isElementNextToIcon?: boolean;
};

type Props = {
  content?: JSX.Element | string | null;
  isInline?: boolean;
  isIconNeeded?: boolean;
  isLabel?: boolean;
  isElementNextToIcon?: boolean;
};

export const TCLinkWrapper = ({
  content,
  isInline,
  isIconNeeded = true,
  isLabel,
  isElementNextToIcon = false,
}: Props) => (
  <Wrapper isInline={isInline} isLabel={isLabel} isElementNextToIcon={isElementNextToIcon} className="tc-link-wrapper">
    {content}
    {isIconNeeded && <ShortcutIcon label="" size="small" testId="external-link-icon" />}
  </Wrapper>
);
