import { useCallback, useState } from 'react';
import { Profile } from '@atlaskit/atlassian-navigation';
import { ButtonItem, MenuGroup, Section } from '@atlaskit/menu';
import Popup from '@atlaskit/popup';
import { useRouterActions } from 'react-resource-router';

import { getConfig } from 'src/config';
import { useUserStore } from 'src/store/userStore';
import { ENABLE_SETTINGS_PAGE } from 'src/featureFlagsKeys';
import { useExperimentValue } from '@hooks/useExperimentValue';

const handleLogout = (): void => {
  const { atlassianAccountUrl } = getConfig();

  window.location.href = `${atlassianAccountUrl}/logout?continue=${encodeURIComponent(
    window.location.href
  )}&application=global-state`;
};

const ProfileContent = ({ handleOpen }: { handleOpen: () => void }) => {
  const { push } = useRouterActions();
  const showSettings = useExperimentValue(ENABLE_SETTINGS_PAGE, 'value', false);

  const handleSettingsClick = useCallback(() => push('/settings/departments'), [push]);

  return (
    <MenuGroup onClick={handleOpen} minWidth={200} maxWidth={400} testId="profile-menu">
      <Section hasSeparator={false}>
        {showSettings && <ButtonItem onClick={handleSettingsClick}>Settings</ButtonItem>}
        <ButtonItem onClick={handleLogout}>Logout</ButtonItem>
      </Section>
    </MenuGroup>
  );
};

const profileImageCSS = {
  borderRadius: '100%',
  height: 24,
  width: 24,
};

export const ProfilePopup = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const [{ user }] = useUserStore();

  const handleOpen = useCallback((): void => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const handleContent = useCallback(() => <ProfileContent handleOpen={handleOpen} />, [handleOpen]);

  const handleTrigger = useCallback(
    (triggerProps) => (
      <Profile
        data-testId="navbar-profile"
        icon={<img data-testid="profile-icon" alt={user.name} style={profileImageCSS} src={user.picture} />}
        onClick={handleOpen}
        isSelected={isOpen}
        tooltip="Logout"
        {...triggerProps}
      />
    ),
    [handleOpen, isOpen, user.name, user.picture]
  );

  return (
    <Popup
      placement="bottom-end"
      content={handleContent}
      isOpen={isOpen}
      onClose={handleOpen}
      trigger={handleTrigger}
    />
  );
};
